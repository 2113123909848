@import url('https://fonts.cdnfonts.com/css/graphik-trial');

html {
	scroll-behavior: smooth;
}

@font-face {
	font-family: 'graphikblack';
	src: url('../fonts/graphikblack-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikblack_italic';
	src: url('../fonts/graphikblackitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikbold';
	src: url('../fonts/graphikbold-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikbold_italic';
	src: url('../fonts/graphikbolditalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikextralight';
	src: url('../fonts/graphikextralight-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikextralight_italic';
	src: url('../fonts/graphikextralightitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphiklight';
	src: url('../fonts/graphiklight-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphiklight_italic';
	src: url('../fonts/graphiklightitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikmedium';
	src: url('../fonts/graphikmedium-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphikmedium_italic';
	src: url('../fonts/graphikmediumitalic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'graphik_regularregular';
	src: url('../fonts/graphikregular-webfont.woff2') format('woff2'),
		url('../fonts/graphikregular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

ul,
li,
ol {
	margin: 0px;
	padding: 0px;
	list-style: none;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0px;
	padding: 0px;
}

b,
br,
header,
span,
section,
strong,
nav,
footer,
form {
	margin: 0px;
	padding: 0px;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #3F6398;
	transition: all 0.4s;
}

a:hover {
	color: #1D6AE5;
}

img {
	max-width: 100%;
}

p {
	margin: 0px;
	padding: 0px;
	color: #000000;
	font-size: 17px;
	line-height: 110%;
	font-weight: 500;
}

.container {
	margin: 0 auto;
	padding: 0px 15px;
	width: 100%;
	max-width: 1458px;
}

.h1 {
	font-size: 42px;
	line-height: 110%;
	font-weight: 500;
}


.h2 {
	font-size: 35px;
	line-height: 110%;
	font-weight: 500;
}

.multistep-main-wrap img{
	height: auto;
}
.right-block-detailsimg img{
	height: auto;
}
.custom-btn {
	background: #1D6AE5;
	color: #fff;
	font-size: 20px;
	line-height: 120%;
	width: 100%;
	min-width: 160px;
	padding: 12px 15px;
	font-weight: 400;
	transition: all 0.4s;
	border-radius: 10px;
	border: #3F6398 solid 1px;
	cursor: pointer;
	display: block;
	max-width: 180px;
	text-align: center;
}

.custom-btn:hover {
	background: #fff;
	color: #1D6AE5;
}

.text-center {
	text-align: center;
}

.custom-row {
	display: flex;
	flex-wrap: wrap;
}

.open-hidden {
	overflow: hidden;
}

.label {
	font-size: 17px;
	line-height: 110%;
	letter-spacing: 0.32px;
	color: #000000;
}

.form-control {
	height: 50px;
	width: 100%;
	border: solid 1px #E1E1E1;
	font-family: "graphiklight";
	background: #fff;
	color: #000000;
	padding: 10px 22px;
	box-shadow: none;
	outline: none;
	font-size: 17px;
	letter-spacing: 0.3px;
  margin-top: 10px;
  border-radius: .25rem;
}

.password-bk {
	padding-right: 50px;
}

.form-control:focus {
	border-color: #FFC72B;
	box-shadow: none;
}

.input-wrap {
	padding-bottom: 28px;
	position: relative;
}

.error-msg {
	font-size: 12px;
	color: red;
	position: absolute;
	top: '75%'
}

.password-wrap {
	position: relative;
}

.password-wrap .password-show {
	height: 20px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	width: 22px;
	text-align: center;
	cursor: pointer;
}

.password-wrap .password-show .eye-show {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
	border: #4288f8 solid 1px;
	border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #1D6AE5;
	border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #1D6AE5;
	border-radius: 4px;
}


/* header css start  */

.header-sec {
	padding: 22px 29px;
	background: #FFFFFF;
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 18%);
}

.header-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.logo-wrap a img {
	width: 100%;
	max-width: 206px;
}


.header-right-info .nap {
	font-size: 22px;
	line-height: 1.2;
}

/* header css end  */



/* globle css responsive start */


@media only screen and (max-width:1199px) {
	body {
		font-size: 16px;
	}

	p {
		font-size: 16px;
	}

	.h1 {
		font-size: 36px;
	}

	.h2 {
		font-size: 30px;
	}

	.form-control {
		font-size: 16px;
		padding: 10px 14px;
		height: 50px;
	}
}

@media only screen and (max-width:767px) {

	.h1 {
		font-size: 28px;
	}

	.h2 {
		font-size: 25px;
	}

	.input-wrap {
		padding-bottom: 20px;
	}

	.label {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.form-control {
		font-size: 15px;
		padding: 10px 12px;
		height: 44px;
	}

	.password-wrap .password-show {
		right: 14px;
	}

	.password-bk {
		padding-right: 35px;
	}
}

/* globle css responsive end */

/* header css start  */

.stickyheader {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	padding: 16px 0;
}

.logo a img {
	width: 120px;
}

/* header css end  */



/* login page css start */

.comman-pagewrap {
	padding: 40px 56px 60px 56px;
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
}
.signup-main {
	padding: 40px 56px 30px;
}
.comman-pagewrap .left-block-details {
	width: 50%;
	padding: 80px 40px 0px 0;
	position: relative;
}
.left-block-details {
	padding: 80px 40px 0px 0;
	position: relative;
}

.logo-block-wrap {
	position: absolute;
	top: 0;
	left: 13px;
}

.login-detail-block {
	max-width: 678px;
	width: 100%;
	margin: 0 auto;
	/* height: calc(100vh - 200px);
	overflow-x: auto;
	padding-right: 10px;
	display: flex;
	align-items: center;
	padding-top: 110px; */
}

.login-detail-inner {
	width: 100%;
	/* overflow: auto; */
	display: flex;
	align-items: center;
	padding-right: 10px;
    margin-bottom: 20px;
}

.login-detail-block {
	width: 100%;
	max-height: 100%;
}

.login-detail-block .h2 {
	margin-bottom: 10px;
	text-align: left;
	font-family: "graphik_regularregular";
}

.login-detail-block .sub-title {
	margin-bottom: 30px;
	font-size: 19px;
	line-height: 110%;
	letter-spacing: 0.32px;
	text-align: left;
}

.quill .ql-toolbar {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.quill .ql-container {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.google-btn {
	width: 100%;
	height: 50px;
	border: #E1E1E1 solid 1px;
	font-size: 18px;
	line-height: 110%;
	font-weight: 400;
	border-radius: 10px;
	padding: 10px 26px;
	position: relative;
	margin-bottom: 35px;
	letter-spacing: 0.32px;
	background-color: #F2F3F5;

}

.google-btn img {
	position: absolute;
	left: 26px;
	top: 50%;
	transform: translateY(-50%);
}

.or-boder-block {
    display: block;
    border: solid 1px #d1d1d1;
    margin-bottom: 30px;
    position: relative;
}

.or-boder-block::before {
	position: absolute;
	content: "OR";
	background: #ffffff;
	height: 55px;
	width: 55px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -27px;
	margin: 0 auto;
	left: 0;
	right: 0;
	letter-spacing: 0.32px;
	font-size: 20px;
	line-height: 110%;
	font-weight: 400;
	font-size: 16px;

}
.custom-legend .highcharts-legend {
  transform: translateY(-10px); /* Move the entire legend up by 10px */
}

.input-wrap-check {
	display: flex;
	justify-content: space-between;
	position: relative;
	padding-bottom: 25px;
}
.no-page-break {
	page-break-inside: avoid; /* Prevents breaking within this element */
	break-inside: avoid; /* For better support across browsers */
  }
.input-wrap-check input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.input-wrap-check label {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 13px;
}

.input-wrap-check label:before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #D6D6D6;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 10px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
	border-radius: 5px;
}

.input-wrap-check input:checked+label:after {
	content: '';
	display: block;
	position: absolute;
	top: 4px;
	left: 8px;
	width: 6px;
	height: 12px;
	border: solid #1D6AE5;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.login-detail-block .custom-btn {
	max-width: 100%;
	font-size: 18px;
	padding: 10px 15px;
	letter-spacing: 0.32px;
	margin-bottom: 15px;

}

.bottom-link-wrap {
	font-size: 19px;
	line-height: 110%;
	letter-spacing: 0.32px;
	text-align: center;
}

.bottom-link-wrap a {
	font-weight: 600;
	font-family: "graphikmedium";
}

.comman-pagewrap .right-block-details {
	width: 50%;
	background: #323232;
	background-image: linear-gradient(#003F50, #000F50);
	border-radius: 50px;
	padding: 40px;
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 0px;
}
.right-block-details {
	background: #323232;
	background-image: linear-gradient(#003F50, #000F50);
	border-radius: 50px;
	padding: 65px;
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 35px;
}

.right-block-details::before {
	position: absolute;
	content: "";
	background: url(../images/subtract.svg);
	width: 129px;
	height: 182px;
	left: 0;
	top: 0;
	background-repeat: no-repeat;
	background-size: contain;
}

.login-img-right {
	width: 100%;
}

.right-block-details .h1 {
	color: #FFFFFF;
	width: 80%;
	margin: 0 auto;
	text-align: left;
	font-family: "graphikmedium";
}
.right-block-details-signup .h1 {
	color: #FFFFFF;
	max-width: 490px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	font-family: "graphikmedium";
}

.right-block-details p {
	color: #FFFFFF;
	text-align: center;
	font-size: 28px;
	line-height: 160%;
	font-weight: 400;
	margin-top: 24px;
	margin-bottom: 5%;
}

.right-block-detailsimg {
	position: relative;
	padding-left: 15%;
}

.right-block-detailsimg .img-ds {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 35px;
    width: 70%;
}

.login-form {
	display: flex;
    flex-direction: column;
    gap: 5px;
}

.highchart-box .highcharts-container .highcharts-root {
	height: 348px;
    margin-top: 12px;
}

.right-block-detailsimg .sd-img {
	position: relative;
	z-index: 9;
	width: 100%;
	max-width: 640px;

}

/* verification css start */

.verification-wrap .h2,
.max-verification {
	max-width: 476px;
	width: 100%;
	margin: 0 auto;
}

.verification-wrap .h2 {
	font-family: "graphikblack";
	padding-bottom: 50px;
}

.verification-wrap .sub-title {
	font-size: 25px;
	font-family: "graphiklight";
}

.verification-wrap .sub-title a {
	font-family: "graphikblack";
	color: #1D6AE5;
}

.max-verification p {
	font-size: 25px;
	text-align: center;
	font-family: "graphiklight";
	line-height: 1.5;
}

.max-verification p+p {
	margin-top: 30px;
}

.verification-right-block .right-block-detailsimg {
	padding-left: 0%;
	text-align: center;
}

.verification-right-block .right-block-detailsimg .sd-img {
	max-width: 482px;
	margin: 0 auto;
}
.input-width {
	width: 50%;
}
.pt-50 {
	padding-top: 50px;
}

/* responsive css start */
@media only screen and (max-width:1530px) {
	.verification-right-block .right-block-detailsimg .sd-img {
		max-width: 300px;
	}
	.login-detail-inner {
		height: calc(100vh - 200px);
	}
}

@media only screen and (max-width:1366px) {
	.comman-pagewrap {
		padding: 30px 25px;
	}

	.right-block-details p {
		font-size: 24px;
		margin-top: 24px;
		margin-bottom: 6%;
	}

	.login-detail-inner {
		height: calc(100vh - 140px);
	}
}

@media only screen and (max-width:1199px) {
	.login-detail-block .custom-btn {
		font-size: 20px;
		padding: 15px 15px;
	}

	.input-wrap-check label {
		gap: 6px;
	}

	.google-btn {
		height: 65px;
		font-size: 20px;
		padding: 10px 26px;

	}

	.verification-wrap .h2 {
		padding-bottom: 35px;
	}

	.verification-wrap .sub-title {
		font-size: 22px;
	}

	.max-verification p {
		font-size: 20px;
	}
	.right-block-details-signup .h1 {
		margin-top: 30px;
	}
}

@media only screen and (max-width:991px) {
	.comman-pagewrap {
		height: 100%;
		gap: 40px;
	}

	.left-block-details {
		width: 100% !important;
		padding: 80px 0px 0px 0;
	}

	.right-block-details {
		width: 100% !important;
		padding: 65px 40px;
	}

	.login-detail-block .sub-title {
		margin-bottom: 30px;
	}

	.login-detail-block {
		height: 100%;
		padding: 0;
	}

	.login-detail-inner {
		height: 100%;
		overflow: visible;
	}

}

@media only screen and (max-width:767px) {
	.comman-pagewrap {
		padding: 20px 15px;
	}

	.login-detail-block .sub-title {
		margin-bottom: 30px;
		font-size: 16px;
	}

	.left-block-details {
		padding: 65px 0px 0px 0 !important;
	}

	.logo-block-wrap {
		left: 0px;
	}

	.logo-block-wrap img {
		height: 25px;
		width: auto;
	}

	.right-block-details {
		padding: 70px 20px;
	}

	.right-block-details p {
		font-size: 16px;
		margin-top: 18px;
	}

	.login-detail-block .custom-btn {
		font-size: 18px;
		padding: 12px 15px;
		margin-bottom: 20px;
	}

	.bottom-link-wrap {
		font-size: 16px;
	}

	.input-wrap-check label {
		gap: 2px;

	}

	.right-block-details::before {
		width: 82px;
	}

	.or-boder-block {
		margin-bottom: 40px;
	}

	.or-boder-block::before {
		height: 40px;
		width: 40px;
		top: -19px;
		font-size: 15px;
		line-height: 100%;
	}

	.google-btn {
		height: 50px;
		font-size: 16px;
		padding: 10px 26px;
		margin-bottom: 45px;
	}

	.google-btn img {
		width: 28px;
		left: 12px;
	}

	.verification-wrap .h2 {
		padding-bottom: 30px;
	}

	.verification-wrap .sub-title {
		font-size: 18px;
	}

	.max-verification p {
		font-size: 18px;
	}
	.input-width {
		width: 100%;
	}
}

/* login page css end */

/* multi step form css start */

.multistep-main-wrap {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.multistep-block-details {
	width: 74%;
	padding: 80px 40px 40px 40px;
	position: relative;
	height: calc(100vh - 82px);
	overflow: auto;
}

.multistep-2-block-details {
	width: 60%;
	padding: 35px 0px 40px 0px;
	position: relative;
	height: calc(100vh - 82px);
	overflow: auto;
}

.multistep-detail-block {
	max-width: 683px;
	width: 100%;
	margin: 0 auto;

}

.multistep-detail-block .h2 {
	margin-bottom: 37px;
	font-family: "graphikmedium";
	max-width: 480px;
	width: 100%;
	font-size: 23px;
}

.multistep-detail-block .input-wrap {
	margin-bottom: 0px;
}

.select-framework-wrap {
	margin-top: 10px;
}

.select-framework-wrap p {
	font-size: 17px;
	line-height: 1;
	margin-bottom: 19px;
}

.select-framework-row {
	display: flex;
	gap: 42px;
	margin-bottom: 34px;
}

.framework-box {
	border: #E1E1E1 solid 1px;
	padding: 13px 0px;
	width: 100%;
	max-width: 210px;
	border-radius: 10px;
	text-align: center;
	cursor: pointer;
	transition: all 0, 4s;
}

.framework-box.active {
	border: #FFC72B solid 3px;
}

.framework-box img {
	width: auto;
	height: 37px;
	object-fit: contain;
}

.framework-box span {
	display: block;
	font-size: 17px;
	line-height: 1;
	font-family: "graphikmedium";
	margin-top: 21px;
}

.next-prev-btn-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
}

.next-prev-btn-wrap .next-prev-btn {
	box-shadow: none;
	border: #CDCDCD solid 1px;
	display: flex;
	gap: 12px;
	font-size: 23px;
	line-height: 1.1;
	letter-spacing: 0.32px;
	padding: 10px 21px;
	border-radius: 6px;
	color: #AEAEAE;
	height: 54px;
	align-items: center;
	min-width: 60px;
	text-align: center;
	justify-content: center;
}

.next-prev-btn-wrap .next-prev-btn.active {
	background: #1D6AE5;
	border-color: #1D6AE5;
	color: #FFFFFF;

}

.next-prev-btn-wrap .prev-btn img {
	transform: rotate(184deg);
	filter: contrast(0.5);
}

.next-prev-btn-wrap .prev-btn.active img {
	filter: inherit;
}

.next-prev-btn-wrap .prev-btn span {
	display: none;
}

.next-prev-btn-wrap .prev-btn.active span {
	display: block;
}

.multistep-block-right {
	background-image: linear-gradient(#003F50 60%, #000F50);
	width: 26%;
	padding: 68px 0 72px 5%;
	display: flex;
	position: relative;
}

.multistep-2-block-right {
	width: 40%;
	height: 100vh;
    overflow: auto;
}

.copy-icon {
	display: flex;
    align-items: center;
    gap: 47px;
}

.multistep-2-img-right .login-img-right {
	width: 100%;
}

.multistep-2-block-right p {
    color: #1a1a1a;
    text-align: left;
    font-size: 16px;
    line-height: 160%;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 1%;
}

.multistep-2-img-right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.multistep-2-img-right .step {
	position: relative;
	min-height: 1em;
	color: rgb(85, 85, 85);
}

.multistep-2-img-right .step+.step {
	margin-top: 66px;
}

.multistep-2-img-right .step>div:first-child {
	position: static;
	height: 0;
}

.multistep-2-img-right .step>div:not(:first-child) {
	padding-left: 50px;
}

.multistep-2-img-right .step.step-active {
	color: #4285f4;
}


/* Circle */
.multistep-2-img-right .circle {
	background: #3F6398;
	position: relative;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 20px;
	color: #3f3f3f;
	border-radius: 100%;
	font-weight: 400;
	text-align: center;
	border: solid 1px #000000;
}

.multistep-2-img-right .step.step-active .circle {
	background-color: #FFC72B;
	border-color: #FFC72B;
	color: #3F6398;
}

/* Vertical Line */
.multistep-2-img-right .circle:after {
	content: " ";
	position: absolute;
	display: block;
	top: 32px;
	right: 50%;
	left: 50%;
	height: 73px;
	width: 2px;
	background-color: #fff;
}

.multistep-2-img-right .circle-border .circle:after {
	display: none;
}






/* Stepper Titles */
.multistep-2-img-right .title {
	font-size: 19px;
	line-height: 130%;
	color: #383838;
	font-weight: 500;
	opacity: 30%;
}

.multistep-2-img-right .caption {
	font-size: 16px;
	line-height: 125%;
	color: #383838;
	opacity: 30%;
	font-weight: 400;
}

.multistep-2-img-right .step.step-active .title,
.multistep-2-img-right .step.step-active .caption {
	opacity: 100%;
}

.multistep-2-img-right .active-title {
	opacity: 100%;
}

.multistep-2-img-right .login-img-right {
	width: 100%;
}

.multistep-block-right p {
	color: #FFFFFF;
	text-align: center;
	font-size: 28px;
	line-height: 160%;
	font-weight: 400;
	margin-top: 24px;
	margin-bottom: 14%;
}

.multistep-img-right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.multistep-img-right .step {
	position: relative;
	min-height: 1em;
	color: grey;
}

.multistep-img-right .step+.step {
	margin-top: 66px;
}

.multistep-img-right .step>div:first-child {
	position: static;
	height: 0;
}

.multistep-img-right .step>div:not(:first-child) {
	padding-left: 50px;
}

.multistep-img-right .step.step-active {
	color: #4285f4;
}


/* Circle */
.multistep-img-right .circle {
	background: #3F6398;
	position: relative;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	border-radius: 100%;
	font-weight: 400;
	text-align: center;
	border: solid 1px #fff;
}

.multistep-img-right .step.step-active .circle {
	background-color: #FFC72B;
	border-color: #FFC72B;
	color: #3F6398;
}

/* Vertical Line */
.multistep-img-right .circle:after {
	content: " ";
	position: absolute;
	display: block;
	top: 32px;
	right: 50%;
	left: 50%;
	height: 73px;
	width: 2px;
	background-color: #fff;
}

.multistep-img-right .circle-border .circle:after {
	display: none;
}






/* Stepper Titles */
.multistep-img-right .title {
	font-size: 19px;
	line-height: 130%;
	color: #fff;
	font-weight: 500;
	opacity: 30%;
}

.multistep-img-right .caption {
	font-size: 16px;
	line-height: 125%;
	color: #fff;
	opacity: 30%;
	font-weight: 400;
}

.multistep-img-right .step.step-active .title,
.multistep-img-right .step.step-active .caption {
	opacity: 100%;
}

.multistep-img-right .active-title {
	opacity: 100%;
}



.right-block-multistep {
	position: relative;
	padding-left: 15%;
}

.right-block-multistep .img-ds {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 52%;


}

.right-block-multistep .sd-img {
	position: relative;
	z-index: 9;
	width: 100%;
	max-width: 640px;

}

/* Upload file Project SetUp Page */
.drag-drop-area {
	border: 2px dashed #eaeaea;
	border-radius: 10px;
	padding: 45px;
	text-align: center;
	color: #6c757d;
	font-family: 'Arial', sans-serif;
	cursor: pointer;
	position: relative;
}

.drag-drop-area p {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
}

.drag-drop-area a {
	color: #007bff;
	text-decoration: none;
	font-weight: 500;
}

.drag-drop-area a:hover {
	text-decoration: underline;
}

.drag-drop-area small {
	display: block;
	margin-top: 5px;
	font-size: 14px;
	color: #adb5bd;
}

.drag-drop-area input[type="file"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}


/* responsive css start */
@media only screen and (max-width:1530px) {}

@media only screen and (max-width:1366px) {
	.multistep-block-details {
		padding: 50px 40px 40px 40px;
	}

	.multistep-2-block-details {
		padding: 20px 0px 40px 0px;
	}

	.multistep-detail-block .h2 {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width:1199px) {
	.select-framework-wrap {
		margin-top: 8px;
	}

	.select-framework-row {
		gap: 20px;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width:991px) {
	.header-sec {
		padding: 18px 16px;
	}

	.logo-wrap a img {
		max-width: 140px;
	}

	.multistep-block-details {
		width: 100%;
		height: auto;
	}

	.multistep-2-block-details {
		width: 100%;
		height: auto;
	}

	.multistep-detail-block {
		max-width: 100%;
	}

	.multistep-block-right {
		padding: 50px 0 50px 5%;
	}

	.right-block-multistep {
		margin-top: 30px;
	}

	.multistep-block-right {
		width: 100%;
	}

	.next-prev-btn-wrap .next-prev-btn {
		font-size: 16px;
		padding: 7px 14px;
		height: 44px;

	}

	.header-right-info .nap {
		font-size: 16px;
		line-height: 1.2;
	}

}

@media only screen and (max-width:767px) {
	.multistep-block-details {
		padding: 50px 16px 35px 16px;
	}

	.multistep-2-block-details {
		padding: 20px 0px 35px 0px;
	}

	.select-framework-wrap {
		margin-top: 5px;
	}

	.framework-box {
		padding: 16px 10px;
	}

	.framework-box img {
		height: 20px;

	}

	.framework-box span {
		font-size: 15px;
		margin-top: 14px;
	}

	.framework-box.active {
		border: #FFC72B solid 1px;
	}

	.select-framework-row {
		gap: 14px;
	}

	.multistep-detail-block .input-wrap {
		margin-bottom: 0;
	}

	.multistep-img-right .step+.step {
		margin-top: 40px;
	}

	.multistep-img-right .title {
		font-size: 18px;
	}

	.multistep-img-right .caption {
		font-size: 15px;
	}

}

/* multi step form css end */

#google-recaptcha {
	z-index: 1;
}