@import url("./assets/css/bootstarp-min.css");
@import url("./assets/css/style.css");

.loginBody {
  background: #fff !important;
}

body {
	margin: 0px;
	padding: 0px;
	font-size: 17px;
	line-height: 110%;
	font-family: "graphik_regularregular", sans-serif;
	font-family: "graphik_regularregular";
	font-weight: 400;
	font-style: normal;
	color: #000000;
  background: #f5f5f9;
}

/* body {
  margin: 0;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.donut-chart .donut-chart-arcs .donut-chart-arcs-path {
  opacity: 1;
}
.donut-chart-legend .donut-chart-legend-item rect{
  opacity: 1;
}